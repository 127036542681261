<template>
<div class="charging--edit">
    <el-card>
      <el-tabs v-model="activeName" :before-leave="beforeLeave">
        <el-tab-pane label="General" name="general"></el-tab-pane>
        <el-tab-pane label="Connector" name="connector"></el-tab-pane>
      </el-tabs>
      <keep-alive>
        <component ref="form" :is="name[activeName]" :data="data[activeName]" @tabChange="tabChange"></component>
      </keep-alive>
    </el-card>
    <el-card>
      <div class="footer">
        <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import generalVue from './component/general.vue'
import connector from './component/connector.vue'
import apis from '@/services/index'
import loading from '@/utils/loading.json'
export default {
  components: { generalVue, connector },
  data () {
    return {
      name: {
        general: generalVue,
        connector: connector
      },
      activeName: 'general',
      data: {
        general: {},
        connector: {}
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    handleSubmitClick () {
      this.$store.state.app.loading = this.$loading(loading)
      this.beforeLeave()
      const params = {
        evse: Object.assign({}, this.data.general,  this.data.connector)
      }
      // console.log(params)
      let api = null
      const arr = []
      if (this.$route.params.id) {
        api = apis.AssetApi.getEvsesEdit
        arr.push(this.$route.params.id)
      } else {
        api = apis.AssetApi.getEvsesAdd
      }
      arr.push(params)
      api(...arr).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess'),
            duration: 1000,
            onClose: () => { this.handleCancelClick() }
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.go(-1)
    },
    tabChange (data, name) {
      this.data[name] = data
    },
    beforeLeave () {
      this.$refs.form.$refs.alterform.handleSubmitClick()
    },
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      if (this.$route.params.id) {
        const data = await apis.AssetApi.getEvsesPreview(this.$route.params.id)
        this.data.connector = {
          evse_connectors_attributes: data.data.evse.evse_connectors_attributes
        }
        const copy = JSON.parse(JSON.stringify(data.data.evse))
        delete copy.evse_connectors_attributes
        copy.input_voltage = copy.input_voltage.toString()
        this.data.general = copy
      }
      this.$store.state.app.loading.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.charging--edit {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
