import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

// "evse_connectors_attributes":

export default {
  formData: [
    {
      span: 16,
      prop: 'display_max_power',
      name: 'input',
      type: 'number',
      label: 'Display Max Power (kW)',
      placeholder: i18n.t('Display Max Power (kW)'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        label: 'This is the max power displayed for the EVSE in the app. This vale is NOT enforced, it is just to be displayed in the app.'
      }
    }, {
      span: 16,
      prop: 'evse_type',
      name: 'select',
      options: [{ name: 'AC', value: 'AC' }, { name: 'DC', value: 'DC' }],
      value: [],
      label: 'EVSE Type',
      placeholder: i18n.t('EVSE Type'),
      Object: {
        filterable: true
      }
    }, {
      span: 16,
      prop: 'input_voltage',
      name: 'group-radio',
      options: [{
        value: '380',
        name: '380 V'
      }, {
        value: '400',
        name: '400 V'
      }, {
        value: '480',
        name: '480 V'
      }],
      label: 'EVSE Input Voltage',
      placeholder: i18n.t('EVSE Input Voltage'),
      Object: { }
    }, {
      span: 16,
      prop: 'max_current',
      name: 'input',
      type: 'number',
      label: 'EVSE Max Current(A)',
      placeholder: i18n.t('EVSE Max Current(A)'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'phase',
      name: 'group-radio',
      label: 'Phase',
      options: [
        { value: 'Single Phase', name: 'Single Phase' },
        { value: '3-Phase', name: '3-Phase' }],
      placeholder: i18n.t('Phase'),
      Object: { }
    },
    {
      span: 16,
      prop: 'phase_rotation',
      name: 'group-radio',
      options: [
        { value: 'RST', name: 'RST' },
        { value: 'RTS', name: 'RTS' },
        { value: 'SRT', name: 'SRT' },
        { value: 'STR', name: 'STR' },
        { value: 'TRS', name: 'TRS' },
        { value: 'TSR', name: 'TSR' }],
      label: i18n.t('Phase Rotation'),
      placeholder: i18n.t('Phase Rotation'),
      Object: {}
    }, {
      span: 16,
      prop: 'charge_point_id',
      name: 'select',
      options: [],
      value: [],
      label: 'Charge Point',
      placeholder: i18n.t('Charge Point'),
      Object: {
        filterable: true,
        name: 'name',
        value: 'id'
      }
    }, {
      span: 16,
      prop: 'tariff_group_id',
      name: 'select',
      options: [],
      value: [],
      label: 'Tariff Group',
      placeholder: i18n.t('Tariff Group'),
      Object: {
        filterable: true,
        value: 'id'
      }
    }, {
      span: 24,
      prop: 'allows_reservation',
      name: 'checkbox',
      label: i18n.t('Allows reservation'),
      placeholder: 'Allows reservation',
      Object: {
      }
    }],
  formData_con: [{
    span: 24,
    prop: 'evse_connectors_attributes',
    name: 'array',
    value: [],
    label: i18n.t('Connector'),
    Object: {
      delete: '_destroy',
      'label-width': '0'
    },
    createDateFirstItem: [{
      span: 16,
      prop: 'connector_type',
      name: 'select',
      options: [
        { name: 'J1772', value: 'J1772' },
        { name: 'Type 2', value: 'Type 2' },
        { name: 'Type 3', value: 'Type 3' },
        { name: 'CHAdeMO', value: 'CHAdeMO' },
        { name: 'CCS 1', value: 'CCS 1' },
        { name: 'CCS 2', value: 'CCS 2' },
        { name: 'GB/T DC', value: 'GB/T DC' },
        { name: 'GB/T AC', value: 'GB/T AC' },
        // { name: 'Schuko', value: 'Schuko' },
        { name: 'Tesla Supercharger', value: 'Tesla Supercharger' },
        { name: 'CEE 16', value: 'CEE 16' },
        { name: 'CEE 32', value: 'CEE 32' },
        { name: 'Inductive Paddle', value: 'Inductive Paddle' },
        { name: 'NEMA 5-20', value: 'NEMA 5-20' },
        { name: 'Type E French Standard ', value: 'Type E French Standard ' },
        { name: 'Type G British Standard', value: 'Type G British Standard' }
      ],
      value: [],
      label: 'Type',
      placeholder: i18n.t('Type'),
      Object: {
        filterable: true
      }
    }]
  }]
}
